import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page';
import Nav from '../components/sponsoren/nav';

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;
    return (
        <SiteWrapper
            title="Sponsoren - SV Kappel"
            description="Beim SV Kappel gibt es viele Möglichkeit als Sponsor oder Unterstützer in Erscheinung zu treten."
        >
            <Nav />
            <Page headerImg={node.frontmatter.headerImg}>{node.html}</Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query BecomeSponsorQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "becomeSponsor" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
